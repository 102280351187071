import Repository from "./Repository";
import ApiV4Repository from "@/repository/ApiV4Repository";
const resource = "/api";

export default {
  createUserToken(payload) {
    return ApiV4Repository.post(`${resource}/jwt/login`, payload);
  },

  confirmActivationToken(payload) {
    return Repository.post(`${resource}/user/confirm-registration`, {
      token: payload,
    });
  },

  registerNewUser(payload) {
    return Repository.post(`${resource}/user/register`, payload);
  },

  resetPassword(payload) {
    return Repository.post(`${resource}/user/reset-password`, {
      username: payload,
    });
  },

  newPassword(payload) {
    return Repository.post(`${resource}/user/reset-password/${payload.token}`, {
      password: payload.password,
    });
  },
};
